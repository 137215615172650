import * as React from "react";
import {NavLink} from "react-router-dom";
import {BusinessDto, MenuDto} from "../../gen/client";
import './DashboardNav.scss';
import {Badge, Button} from "antd";
import {getMenusPath, RoutesEnum} from "../../RoutesEnum";
import {useEffect, useState} from "react";
import {DownOutlined, UpOutlined, AppstoreOutlined, SettingOutlined, QuestionCircleOutlined, MessageOutlined} from "@ant-design/icons";
import Loader from "../misc/Loader";
import designs_icon_path from "../../img/designs.svg";
import om_icon_path from "../../img/online-menu.svg";

export interface DashboardNavProps {
  business: BusinessDto;
  menus: MenuDto[];
  detailed: boolean;
  onClick?: () => void;
}

export default function DashboardNav({business, menus, detailed, onClick}: DashboardNavProps) {
  const [defaultMenuId, setDefaultMenuId] = useState('new');

  const [isSettingsToggled, setSettingsToggled] = useState(false);
  const [isMenusToggled, setMenusToggled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!business.id);
  }, [business]);

  useEffect(() => {
    const active = menus.filter(it => !it.removed);
    setDefaultMenuId(active.length > 0 ? active[0].id : 'new')
  }, [menus]);

  function isMenusSelected(match: any, location: any) {
    return (location.pathname.indexOf(RoutesEnum.MenusPath) > -1 || location.pathname === RoutesEnum.Dashboard);
  }

  function isSettingsSelected(match: any, location: any) {
    return location.pathname.indexOf(RoutesEnum.DashboardSettings) > -1;
  }

  function isCustomerSelected(match: any, location: any) {
    return location.pathname.indexOf(RoutesEnum.DashboardCustomers) > -1;
  }

  function isOrdersSelected(match: any, location: any) {
    return location.pathname.indexOf(RoutesEnum.DashboardOrders) > -1;
  }

  function isDesignsSelected(match: any, location: any) {
    return location.pathname.indexOf(RoutesEnum.DashboardDesign) > -1;
  }

  function isOverviewSelected(match: any, location: any) {
    return (location.pathname.indexOf(RoutesEnum.DashboardBusiness) > -1)
      && !isMenusSelected(match, location);
  }

  function onLinkClick() {
    if (onClick) onClick();
  }

  function chatWithUs() {
    window.$zoho.salesiq.chat.start();
  }

  return (
    <nav>
      <ul>
        {loading && <li><Loader small /></li>}
        {!loading && <>
        {/*<li className={"overview-tab"}>
          <NavLink className={"nv"} to={`${RoutesEnum.DashboardBusiness}/${business.id}`} isActive={isOverviewSelected} onClick={onLinkClick}>
            <label className="nav-icon"><AppstoreOutlined/>Overview</label>
          </NavLink>
        </li>*/}
        <li className={"menus-tab"}>
          {!detailed && <NavLink className={"nv"} to={getMenusPath(business.id, defaultMenuId)} isActive={isMenusSelected} onClick={onLinkClick}>
            <label className="nav-icon"><img src={om_icon_path} alt=""/>Menus</label>
          </NavLink>}

          {detailed && <div className={'submenu'}>
            <Button type={'link'} className={isMenusToggled ? 'active' : ''} onClick={() => setMenusToggled(!isMenusToggled)}>
              <label className="nav-icon"><img src={om_icon_path} alt=""/>Menus</label>
              {!isMenusToggled && <DownOutlined className={"arrow"}/>}
              {isMenusToggled && <UpOutlined className={"arrow"}/>}
            </Button>
            <ul className={isMenusToggled ? 'vis' : ''}>
              {menus.map(it => <li key={it.id}>
                <NavLink className={"nv truncate"} to={getMenusPath(business.id, it.id)} onClick={onLinkClick}>
                  <label>{it.name || 'Empty menu'}</label>
                </NavLink>
              </li>)}
              <li>
                <NavLink className={"nv truncate"} to={RoutesEnum.DashboardMenusNew} onClick={onLinkClick}>
                  <label>+ New Menu</label>
                </NavLink>
              </li>
            </ul>
          </div>}
        </li>
        <li className={"design-tab"}>
          <NavLink className={"nv"} to={`${RoutesEnum.DashboardDesign}`} isActive={isDesignsSelected} onClick={onLinkClick}>
            <label className="nav-icon"><img src={designs_icon_path} alt=""/>Appearance</label>
          </NavLink>
        </li>
        {business.orderingEnabled && <li className={"orders-tab"}>
          <Badge dot={true}>
            <NavLink className={"nv"} to={RoutesEnum.DashboardOrders} isActive={isOrdersSelected}>
              <label>Orders</label>
            </NavLink>
          </Badge>
        </li>}
        {business.orderingEnabled && <li className={"customers-tab lg"}>
          <NavLink className={"nv"} to={`${RoutesEnum.DashboardCustomers}`} isActive={isCustomerSelected}>
            <label>Customers</label>
          </NavLink>
        </li>}
        <li className={"settings-tab lg"}>
          {!detailed && <NavLink className={"nv"} to={`${RoutesEnum.DashboardSettings}`} isActive={isSettingsSelected} onClick={onLinkClick}>
            <label className="nav-icon"><SettingOutlined/>Settings</label>
          </NavLink>}

          {detailed && <div className={'submenu'}>
            <Button type={'link'} className={isSettingsToggled ? 'active' : ''} onClick={() => setSettingsToggled(!isSettingsToggled)}>
              <label className="nav-icon"><SettingOutlined/>Settings</label>
              {!isSettingsToggled && <DownOutlined className={"arrow"}/>}
              {isSettingsToggled && <UpOutlined className={"arrow"}/>}
            </Button>
            <ul className={isSettingsToggled ? 'vis' : ''}>
              <li>
                <NavLink className={"nv"} to={`${RoutesEnum.DashboardSettings}`} onClick={onLinkClick}>
                  <label>Location</label>
                </NavLink>
              </li>
              <li>
                <NavLink className={"nv"} to={`${RoutesEnum.DashboardSettingsHours}`} onClick={onLinkClick}>
                  <label>Business Hours</label>
                </NavLink>
              </li>
              <li>
                <NavLink className={"nv"} to={`${RoutesEnum.DashboardSettingsStatus}`} onClick={onLinkClick}>
                  <label>Status</label>
                </NavLink>
              </li>
              <li>
                <NavLink className={"nv"} to={`${RoutesEnum.DashboardSettingsSEO}`} onClick={onLinkClick}>
                  <label>SEO</label>
                </NavLink>
              </li>
              {/*<li>*/}
              {/*    <NavLink className={"nv"} to={`${RoutesEnum.DashboardSettingsSavedVersions}`} onClick={onLinkClick}>*/}
              {/*        <label>Saved Versions</label>*/}
              {/*    </NavLink>*/}
              {/*</li>*/}
            </ul>
          </div>}
        </li>
        <div className={'nav-separator'}></div>
        <li className={'mobile-nav-option'}>
          <a className={'nv'} href={'https://musthavemenus27.zohodesk.com/portal/en/kb'} target={'_blank'} rel={'noreferrer'}>
            <label className="nav-icon"><QuestionCircleOutlined/>Help Center</label>
          </a>
        </li>
        <li className={'mobile-nav-option'}>
          <Button className={'nv'} onClick={chatWithUs}>
            <label className="nav-icon"><MessageOutlined/>Chat with us</label>
          </Button>
        </li>
        {/*{!business.orderingEnabled && <li className={"lg"}>*/}
        {/*  <Button type={'link'} onClick={() => setEnableModal(true)}>*/}
        {/*    <label>Online Ordering <span className={'beta'}>Beta</span></label>*/}
        {/*  </Button>*/}
        {/*</li>}*/}
        </>}
      </ul>
    </nav>
  );
}
