import * as React from 'react';
import { Button, Input, Switch, Tooltip } from "antd";
import { Uploader, ImageUploaderTypeEnum } from "../../Uploader";
import Storage from "../../../util/Storage";
import { ImageTypeEnum, BusinessDto } from "../../../gen/client";
import { MenuItemModifier, MenuNode } from "../../../domain/Menu";
import MenuItemSizes from "./MenuItemSizes";
import ItemView from "./ItemView";
import styles from './MenuItem.module.scss';
import "./MenuItem.scss"
import MenuItemModifiers from './MenuItemModifiers';
import { MenuDataType, MenuItemModifierDetail } from '../../../util/types';
import { DeleteOutlined, PictureOutlined, UpOutlined } from "@ant-design/icons";
import DeleteMenuDataModal from './DeleteMenuDataModal';
import { useState } from 'react';

interface MenuItemProps {
  item: MenuNode;
  sectionId: string;
  business: BusinessDto;
  currency: string;
  modifiers: MenuItemModifierDetail[];
  onModifierRemovedGlobal: (m: MenuItemModifier) => void;
  onItemUpdate: (item: Partial<MenuNode>) => void;
  onItemRemove: (sectionId: string, itemId: string) => void;
  onCurrencyChange: (currency: string) => void;
  editMode?: boolean;
  onClick: () => void;
  onCancel: () => void;
}

export default function MenuItem({ item, sectionId, business, currency, modifiers, onItemRemove, onItemUpdate, editMode, onModifierRemovedGlobal, onClick, onCancel }: MenuItemProps) {
  const [isRemoveModal, setRemoveModal] = useState(false);

  function onModifierAddOrEdit(m: MenuItemModifier) {
    if (!item.modifiers) {
      item.modifiers = [];
    }

    const idx = item.modifiers.findIndex(it => it.id === m.id);

    if (idx < 0) {
      item.modifiers.push(m);
    } else {
      item.modifiers[idx] = m;
    }

    onItemUpdate({ modifiers: item.modifiers });
  }

  function onModifierRemove(m: MenuItemModifier, global?: boolean) {
    if (global) {
      onModifierRemovedGlobal(m);
    }

    onItemUpdate({ modifiers: item.modifiers.filter(it => it.id !== m.id) });
  }

  function removeItemImage() {
    onItemUpdate({ preview: null });
  }


  function onRemove(e: React.MouseEvent) {
    e.stopPropagation();
    onItemRemove(sectionId, item.id);
  }

  return <>
    {!editMode &&
      <ItemView sectionId={sectionId} id={item.id} name={item.name} description={item.description} preview={item.preview} previewSource={item.previewSource} prices={item.prices} sizes={item.sizes} business={business}
        available={item.available} extras={!business?.orderingEnabled ? item.extras : undefined} currency={currency} onItemUpdate={onItemUpdate} setRemoveModal={setRemoveModal} onClick={onClick} />}

    {editMode &&
      <div className={`${styles.item} item`}>
        <div className="item-actions">
          <Tooltip title={item.available ? 'Hide' : 'Show'} placement="bottom">
            <Switch className={'scc'} defaultChecked={item.available} onChange={(available) => onItemUpdate({ available })} size={"small"} />
          </Tooltip>
          <Tooltip title="Delete" placement="bottom">
            <Button type={"link"} onClick={(e) => { e.stopPropagation(); setRemoveModal(true) }}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Collapse Item" placement="bottom">
            <UpOutlined className={'close-icon'} onClick={(e) => { e.stopPropagation(); onCancel(); }} />
          </Tooltip>
        </div>

        <div className="name-icon-actions">
          <Input size="large" className={'name'} defaultValue={item.name} placeholder={'Item Name'} autoFocus onBlur={(ev) => onItemUpdate({ name: ev.target.value })} />
          <Uploader
            className={'item'}
            hasDropdown={true}
            memberId={Storage.getMemberId()} imageType={ImageTypeEnum.MenuItem} widgetType={ImageUploaderTypeEnum.Card} path={item.preview} source={item.previewSource}
            addLabel={<div><PictureOutlined/><span>Add image</span></div>} changeLabel={'change'} removeItemImage={removeItemImage} onUploadSuccess={(preview, previewSource) => onItemUpdate({ preview, previewSource })}
          />
        </div>

        <Input.TextArea defaultValue={item.description} placeholder={'Description'} style={{ resize: 'none', marginTop: '15px' }} onBlur={(ev) => onItemUpdate({ description: ev.target.value })} />

        <section>
          <MenuItemSizes business={business} item={item} onItemUpdate={onItemUpdate} />
        </section>

        {business?.orderingEnabled ? (
          <><label>{'Modifiers'}</label>
            <section>
              <MenuItemModifiers item={item} currency={currency} business={business} onModifierAddOrEdit={onModifierAddOrEdit}
                onModifierRemove={onModifierRemove} existingModifiers={modifiers} />
            </section>
          </>
        ) : <Input.TextArea defaultValue={item.extras} maxLength={500} placeholder={'Extras'} style={{ resize: 'none', marginTop: '15px' }} onBlur={(ev) => onItemUpdate({ extras: ev.target.value })} />
        }
      </div>}
      
    <DeleteMenuDataModal visible={isRemoveModal} onConfirm={onRemove} onCancel={(e) => { e.stopPropagation(); setRemoveModal(false) }} type={MenuDataType.ITEM} />
  </>
}
