import * as React from 'react';
import {createRef, useCallback, useEffect, useRef, useState} from 'react';
import {Button, Input, InputRef, Switch, Tooltip} from "antd";
import {scrollToElement, tick, uuid} from "../../../util/Utils";
import MenuSection from "./MenuSection";
import {MenuItemModifier, MenuNode, MenuNodeType} from "../../../domain/Menu";
import {BusinessDto, MenuDto, TimberItemDto} from "../../../gen/client";
import MenuApi from "../../../api/MenuApi";
import {handleServerError} from "../../../util/ErrorHandler";
import {fixVersionedNodes, getMenuModifiers, timberItemToMenuItem} from "../../../services/MenuService";
import styles from './DashboardMenu.module.scss';
import {DeleteOutlined} from "@ant-design/icons/lib";
import {MenuDataType, MenuItemModifierDetail, ReferenceProp} from '../../../util/types';
import {Action, hideUndoNotification, History, showUndoNotification, undoNodeRemoval} from '../../../services/HistoryService';
import ImportItemModal from "./ImportItemModal";
import {useHistory} from 'react-router-dom';
import {isNewMenusPath} from '../../../RoutesEnum';
import DeleteMenuDataModal from "./DeleteMenuDataModal";
import DeleteLastMenuModal from "./DeleteLastMenuModal";

interface RestaurantMenuProps {
  business: BusinessDto;
  menu: MenuDto;
  scrollToBottom: boolean;
  selectedSection: MenuNode;
  manualSelectedSection: MenuNode;
  setManualSelectedSection: (section: MenuNode) => void;
  setSelectedSection: (section: MenuNode) => void;
  onCurrencyChange: (currency: string) => void;
  onMenuRemoved: (menuId: string) => void;
  onMenuUpdate: (menu: Partial<MenuDto>, scrollToBottom?: boolean) => void;
  menus: MenuDto[];
  published: boolean;
}

function useOnClickOutside(ref: React.MutableRefObject<any>, handler: () => void) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const classList = (event.target as Element).classList;

      if (classList.contains('ant-upload') ||
        classList.contains('ant-dropdown-menu-title-content') ||
        classList.contains('ant-dropdown-menu-item') ||
        !!event.composedPath().filter(it => (it as Element).classList?.contains('ant-modal')).length) return;

      if (!ref.current || ref.current.contains(event.target)) return;

      handler();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default function DashboardMenu({business, menu, scrollToBottom, selectedSection, manualSelectedSection, setManualSelectedSection, setSelectedSection, onCurrencyChange, onMenuRemoved, onMenuUpdate, menus, published}: RestaurantMenuProps) {
  const history = useHistory();

  const [nodes, setNodes] = useState([new MenuNode(uuid())]);
  const [nodePositionPairs, setNodePositionPairs] = useState([] as any);
  const [modifiers, setModifiers] = useState([] as MenuItemModifierDetail[]);
  const [activeSection, setActiveSection] = useState(null as MenuNode);

  const [isActive, setActive] = useState(false);
  const [isRemoveModal, setRemoveModal] = useState(false);
  const [isImportModal, setImportModal] = useState(false);
  const [isLastMenuPopup, setLastMenuPopup] = useState(false);
  const [scrollToBottomSectionId, setScrollToBottomSectionId] = useState(null);
  const [isNew, setNew] = useState(false);

  const [refs, setRefs] = useState([] as ReferenceProp[]);
  const bottomEl = useRef(null);
  const [enabledItems, setEnabledItems] = useState([] as string[]);

  const nameInputRef = useRef(null as InputRef);
  const divRef = useRef();
  const handler = useCallback(() => {
    setTimeout(() => {
      setEnabledItems([]);
    }, 100);
  }, []);
  useOnClickOutside(divRef, handler);

  useEffect(() => {
    setActive(menu.id && menu.active);

    const nodes = fixVersionedNodes(menu);
    setNodes(nodes);
    setModifiers(getMenuModifiers(nodes));

    setRefs(nodes.reduce((acc: any, section: MenuNode) => {
      acc[section.id] = createRef();
      return acc;
    }, {}));

    hideUndoNotification();
  }, [menu]);

  useEffect(() => {
    setNew(isNewMenusPath(history.location.pathname));
  }, [history.location.pathname]);

  useEffect(() => {
    if (manualSelectedSection && manualSelectedSection.id) {
      const ref = (refs as ReferenceProp)[manualSelectedSection.id];
      if (ref) {
        const scroller = document.querySelector('main>.scroller');
        scroller.scrollTop = ref.current.offsetTop;
        setManualSelectedSection(null);
      }
    }
  }, [manualSelectedSection, refs]);

  useEffect(() => {
    const toBeNPP = [] as any[];
    if (menu) {
      nodes.forEach(n => {
        const ref = (refs as ReferenceProp)[n.id];
        if (ref) {
          if (toBeNPP.indexOf((npp: any) => npp[0] === n.id) < 0) {
            toBeNPP.unshift([n.id, ref.current.offsetTop]);
          }
        }
      })
    }
    setNodePositionPairs([...toBeNPP]);
  }, [menu, nodes, refs]);

  useEffect(() => {
    const scroller = document.querySelector('main>.scroller') as HTMLElement;

    function pickSectionByScroll() {
      const selectedNPP = scroller.scrollTop > scroller.scrollHeight - scroller.offsetHeight - 20 ? nodePositionPairs[0] : nodePositionPairs.find((npp: any) => scroller.scrollTop >= npp[1]);
      if (selectedNPP && (!selectedSection || selectedNPP[0] !== selectedSection.id)) {
        const selectingSection = nodes.find(n => n.id === selectedNPP[0]);
        setSelectedSection(selectingSection);
      }
    }
    pickSectionByScroll();

    const onScroll: EventListener = () => {
      pickSectionByScroll();
    };
    scroller.addEventListener("scroll", onScroll);

    return () => {
      scroller.removeEventListener("scroll", onScroll);
    }
  }, [nodePositionPairs, selectedSection]);

  function handleNameInputRef(el: InputRef) {
    if (el) {
      nameInputRef.current = el;
      if (!nameInputRef.current.input.value) {
        nameInputRef.current.focus();
      }
    }
  }

  function onItemClick(itemId: string) {
    setEnabledItems([itemId]);
  }

  function handleBottomEl(el: HTMLDivElement) {
    bottomEl.current = el;
    if (scrollToBottom) {
      scrollToElement(bottomEl.current);
    }
  }

  function onSectionUpdate(id: string, section: Partial<MenuNode>) {
    const newNodes = [...nodes];
    const idx = newNodes.findIndex(it => it.id === id);
    newNodes[idx] = {...newNodes[idx], ...section};

    onMenuUpdate({
      ...menu,
      nodes: JSON.stringify(newNodes)
    });
  }

  function addSection() {
    const sectionId = uuid();
    onMenuUpdate({
      ...menu,
      nodes: JSON.stringify([...nodes, new MenuNode(sectionId)])
    }, true);
    setScrollToBottomSectionId(sectionId);
  }

  function onSectionRemove(id: string) {
    const index = nodes.findIndex(section => section.id === id);
    const filtered = nodes.filter(section => section.id !== id);

    onMenuUpdate({...menu, nodes: JSON.stringify(filtered)});
    pushHistory(Action.RemoveSection, filtered, {index, node: nodes[index]});
  }

  function onItemRemove(sectionId: string, itemId: string) {
    const section = nodes.find(it => it.id === sectionId);
    if (section) {
      const index = section.nodes.findIndex(it => it.id === itemId);
      const node = section.nodes[index];
      section.nodes = section.nodes.filter(it => it.id !== itemId);

      onMenuUpdate({...menu, nodes: JSON.stringify(nodes)});
      pushHistory(Action.RemoveItem, nodes, {sectionId, index, node});
    }
  }

  function pushHistory(action: Action, nodes: MenuNode[], payload: any) {
    tick(() => {
      History.push(action, payload);
      showUndoNotification(action, () => undoRemoval(nodes));
    });
  }

  function undoRemoval(nodes: MenuNode[]) {
    const entry = History.last();
    const updated = undoNodeRemoval(entry, [...nodes]);
    onMenuUpdate({...menu, nodes: JSON.stringify(updated)});
    History.clear();
    hideUndoNotification();
  }

  function onItemUpdate(sectionId: string, itemId: string, patch: Partial<MenuNode>) {
    const sectionIdx = nodes.findIndex(it => it.id === sectionId);
    const section = nodes[sectionIdx];

    const itemIdx = section.nodes.findIndex(it => it.id === itemId);
    const item = section.nodes[itemIdx];

    section.nodes[itemIdx] = {...item, ...patch};
    nodes[sectionIdx] = section;

    onMenuUpdate({
      ...menu,
      nodes: JSON.stringify(nodes)
    });
  }

  function onItemAdd(sectionId: string) {
    const id = uuid();
    nodes.find(it => it.id === sectionId).nodes.push(new MenuNode(id, MenuNodeType.ITEM, [null]));
    onMenuUpdate({
      ...menu,
      nodes: JSON.stringify(nodes)
    });

    setScrollToBottomSectionId(sectionId);
    setEnabledItems([id]);
  }

  function onCancel() {
    setEnabledItems([]);
  }

  function onItemSort(sectionId: string, newNodes: MenuNode[]) {
    const section = nodes.find(it => it.id === sectionId);
    if (section) {
      section.nodes = newNodes;
    }

    onMenuUpdate({
      ...menu,
      nodes: JSON.stringify(nodes)
    });
  }

  function remove() {
    MenuApi.removeMenu(menu.id).then(() => {
      onMenuRemoved(menu.id);
    }).catch(err => {
      handleServerError(err);
    });
  }

  function onModifierRemovedGlobal(m: MenuItemModifier) {
    nodes.forEach(n => {
      n.nodes.forEach(c => {
        if (c.modifiers) {
          c.modifiers = c.modifiers.filter(it => it.id !== m.id);
        }
      });
    });
  }

  function onItemImport(section: MenuNode) {
    setImportModal(true);
    setActiveSection(section);
  }

  function importItems(items: TimberItemDto[]) {
    setImportModal(false);
    activeSection.nodes = [...activeSection.nodes, ...timberItemToMenuItem(items)];
    onMenuUpdate({...menu, nodes: JSON.stringify(nodes)});
  }

  function showDeleteModal() {
    if (menus.filter(it => !it.removed).length > 1 || !published) {
      setRemoveModal(true);
    } else {
      setLastMenuPopup(true);
    }
  }

  function getDefaultMenuName() {
    const candidates = menus.map(it => it.name)
                            .filter(it => {
                              try {
                                return it.startsWith('Menu') && parseInt(it.replace('Menu ', ''));
                              } catch (e) {
                                return false;
                              }
                            })
                            .map(it => parseInt(it.replace('Menu ', '')))
                            .sort();

    return `Menu ${candidates.length + 1}`;
  }

  return (<div className={styles.menu}>
    <header>
      <div>
        <Input key={menu.name} size="large" className={`mn-name ${!menu.name && !isNew ? styles.err : ''}`} placeholder={'Menu Name'} defaultValue={menu.name} onBlur={(ev) => onMenuUpdate({name: ev.target.value || getDefaultMenuName()})} ref={handleNameInputRef}/>
        {menu.id && <section className={styles.publishSection}>
          <Tooltip title={isActive ? 'Hide' : 'Show'} placement="bottom">
            <Switch className={'scc'} checked={isActive} onChange={() => onMenuUpdate({active: !isActive})} size={"small"}/>
          </Tooltip>
        </section>}
        {menu.id && 
        <Tooltip title="Delete" placement="bottom">
          <span className={`${styles.rt} ${styles.remove}`}><DeleteOutlined onClick={showDeleteModal}/></span>
        </Tooltip>}
      </div>

      {!menu.name && !isNew && <span className={styles.err}>Please add a menu name.</span>}
      {(menu.name || isNew) && <span className={styles.err}>&nbsp;</span>}

      <div>
        <Input className={`mn-details`} placeholder={'Menu Note'} defaultValue={menu.details} onBlur={(ev) => onMenuUpdate({details: ev.target.value})}/>
      </div>
    </header>

    <div className={'sections'} ref={divRef}>
      {nodes.map(section =>
        <MenuSection key={section.id} section={section} business={business} modifiers={modifiers} currency={business && business.config ? business.config.currency : "$"} scrollToBottom={section.id === scrollToBottomSectionId}
                     onSectionRemove={onSectionRemove} onItemAdd={onItemAdd} onItemSort={onItemSort} onItemRemove={onItemRemove} reference={(refs as ReferenceProp)[section.id]}
                     onItemUpdate={onItemUpdate} onCurrencyChange={onCurrencyChange} onModifierRemovedGlobal={onModifierRemovedGlobal} onSectionUpdate={(s) => onSectionUpdate(section.id, s)}
                     onItemImport={onItemImport} onItemClick={onItemClick} editItemIds={enabledItems} onCancel={onCancel} />)
      }
    </div>

    <div ref={handleBottomEl}/>

    <footer>
      <Button size={'large'} onClick={addSection} className={styles.newSection + ' btn-sec-1'}>+ New Group</Button>
    </footer>

    <DeleteMenuDataModal visible={isRemoveModal} onConfirm={() => {setRemoveModal(false); remove()}} onCancel={() => setRemoveModal(false)} type={MenuDataType.MENU} />
    <DeleteLastMenuModal visible={isLastMenuPopup} onConfirm={() => setLastMenuPopup(false)} />
    <ImportItemModal business={business} visible={isImportModal} onClose={() => setImportModal(false)} onSuccess={importItems}/>
  </div>);
};
