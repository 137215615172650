import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import "./Uploader.scss";
import MemberApi from "../api/MemberApi";
import {ImageDtoSourceEnum, ImageTypeEnum, MemberImageDto} from "../gen/client";
import {Button, Dropdown, Menu, Upload} from "antd";
import {handleServerError} from "../util/ErrorHandler";
import {getImagePath} from "../services/ImageService";
import {DeleteOutlined, EditOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined} from "@ant-design/icons";
import Loader from "./misc/Loader";
import {MenuInfo} from "rc-menu/lib/interface";
import ImageApi from "../api/ImageApi";
import {fromBase64ToBlob, getBase64Response, getMimeStringFromBase64, rotateImage} from "../util/Utils";
import FileTooBigModal from "./misc/FileTooBigModal";
import {ALLOWED_IMAGE_TYPES, MAX_IMAGE_SIZE} from "../util/constants";
import FileInvalidTypeModal from "./misc/FileInvalidTypeModal";
import BrandsApi from "../api/BrandsApi";
import {useRecoilState} from "recoil";
import {currentLocation} from "../store/atoms";
import ChooseImageModal from "./ChooseImageModal";
import {isOOImage} from "../services/MenuService";

export enum ImageUploaderTypeEnum {
  Button,
  Card,
  Circle,
}

interface UploaderProps {
  memberId: number;
  imageType: ImageTypeEnum;
  widgetType: ImageUploaderTypeEnum;
  onUploadSuccess: (path: string, source: ImageDtoSourceEnum) => void;
  addLabel: ReactNode;
  changeLabel?: ReactNode;
  removeItemImage?: () => void;
  path?: string;
  source?: ImageDtoSourceEnum;
  className?: string;
  hidePreview?: boolean;
  hasDropdown?: boolean;
  hideModal?: boolean;
}

export function Uploader({memberId, imageType, widgetType, path, addLabel, changeLabel, className, hidePreview, hasDropdown = false, removeItemImage, onUploadSuccess, source, hideModal}: UploaderProps) {
  const [preview, setPreview] = useState(path);
  const [loading, setLoading] = useState(false);
  const [showFileTooBigModal, setShowFileTooBigModal] = useState(false);
  const [showFileInvalidModal, setShowFileInvalidModal] = useState(false);
  const [chooseImageModal, setChooseImageModal] = useState(false);
  const [location] = useRecoilState(currentLocation);

  function rotate(right: boolean = false) {
    setLoading(true);
    ImageApi.getImage(path).then(resp => {
      const base64 = getBase64Response(resp);
      const mimeString = getMimeStringFromBase64(base64);

      rotateImage(base64, mimeString, right).then(rotatedBase64 => {
        const blob = fromBase64ToBlob(rotatedBase64, mimeString);
        const file = new File([blob], path.substring(path.lastIndexOf('/')), { lastModified: new Date().getTime(), type: blob.type });

        uploadFile(file);
      }).catch((err) => {
        handleServerError(err);
        setLoading(false);
      });
    });
  }

  useEffect(() => {
    setPreview(path);
  }, [path]);

  const uploadFile = (file: File) => {
    setLoading(true);

    if (imageType === ImageTypeEnum.BusinessLogo) {
      BrandsApi.uploadLogo(location.internalId, file)
        .then((resp) => {
          onUploadSuccess(resp.data.path, ImageDtoSourceEnum.MyBrand);
          setPreview(resp.data.path);
          setLoading(false);
        })
        .catch((err) => {
          handleServerError(err);
          setLoading(false);
        });
    } else {
      MemberApi.uploadImage(memberId, location.internalId, imageType, file)
        .then((resp) => {
          handleUploadSuccess(resp.data.path, ImageDtoSourceEnum.Ordering);
          setLoading(false);
        })
        .catch((err) => {
          handleServerError(err);
          setLoading(false);
        });
      }
  };

  function handleUploadSuccess(path: string, source: ImageDtoSourceEnum) {
    onUploadSuccess(path, source);
    setPreview(path);
  }

  function onExistingImageChoice(img: MemberImageDto) {
    handleUploadSuccess(img.path, img.source === 'oo' ? ImageDtoSourceEnum.Ordering : ImageDtoSourceEnum.MyBrand);
    setChooseImageModal(false);
  }

  const beforeUpload = (file: File) => {
    if (ALLOWED_IMAGE_TYPES.indexOf(file.type) < 0) {
      setShowFileInvalidModal(true);
      return false;
    }

    const mbSize = Math.floor(file.size / (1024 * 1024));
    if (mbSize >= MAX_IMAGE_SIZE) {
      setShowFileTooBigModal(true);
      return false;
    } else {
      setChooseImageModal(false);
      uploadFile(file);
      return false;
    }
  };

  const toggleDropdown = (info: MenuInfo) => {
    info.domEvent.stopPropagation();
    info.domEvent.preventDefault();
    switch (+info.key) {
      case 0: setChooseImageModal(true); break;
      case 1: rotate(); break;
      case 2: rotate(true); break;
      case 3: removeItemImage();
    }
  };

  const menu = (
    <Menu className={'upload-img-menu'} onClick={toggleDropdown}>
      <Menu.Item key="0"><SwapOutlined/> Replace Photo</Menu.Item>
      <Menu.Item key="1"><RotateLeftOutlined/> Rotate Left</Menu.Item>
      <Menu.Item key="2"><RotateRightOutlined/> Rotate Right</Menu.Item>
      <Menu.Item key="3"><DeleteOutlined/> Delete Photo</Menu.Item>
    </Menu>
  );

  return (
    <div className={`uploader ${hasDropdown ? 'with-drop' : ''} ${className || ""}`}>
      {loading && <Loader small/>}

      {!hidePreview && preview && <div className='preview' style={{ backgroundImage: `url(${getImagePath(preview, isOOImage(source))})`}}/>}

      {hasDropdown && preview && (
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type={"link"}>{!loading && <EditOutlined />}</Button>
        </Dropdown>
      )}

      {(!hasDropdown || !preview) && hideModal && (
        <div className={'ant-upload ant-upload-select ant-upload-select-picture-card'}>
          <Upload className={!preview ? 'no': ''} listType={widgetType === ImageUploaderTypeEnum.Card ? "picture-card" : null} beforeUpload={beforeUpload} showUploadList={false} progress={null}>
            <Button type={widgetType === ImageUploaderTypeEnum.Card ? "link" : null}>
              {!loading && (preview ? changeLabel || addLabel : addLabel)}
            </Button>
          </Upload>
        </div>
      )}

      {(!hasDropdown || !preview) && !hideModal && (
        <div className={'ant-upload ant-upload-select ant-upload-select-picture-card'}>
          <Button type={widgetType === ImageUploaderTypeEnum.Card ? "link" : null} onClick={() => setChooseImageModal(true)}>
            {!loading && (preview ? changeLabel || addLabel : addLabel)}
          </Button>
        </div>
      )}

      {showFileTooBigModal && <FileTooBigModal onOk={() => setShowFileTooBigModal(false)} maxSize={MAX_IMAGE_SIZE}/>}
      {showFileInvalidModal && <FileInvalidTypeModal onOk={() => setShowFileInvalidModal(false)}/>}
      {chooseImageModal && <ChooseImageModal memberId={memberId} onClose={() => setChooseImageModal(false)} onUpload={beforeUpload} onChoice={onExistingImageChoice} />}
    </div>
  );
}
